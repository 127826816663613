// refresh-on-focus.tsx
"use client";

import { useRouter } from "next/navigation";
import { FC, useEffect } from "react";

const useRefreshOnFocus = () => {
  const router = useRouter();

  useEffect(() => {
    const onFocus = () => {
      router.refresh();
    };

    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [router]);
};

export const RefreshOnFocus: FC = () => {
  useRefreshOnFocus();

  return null;
};
