"use client";

import { useDisclosure, usePrevious } from "@mantine/hooks";
import { useSession } from "next-auth/react";
import { useQueryState } from "nuqs";
import { FC, ReactNode, createContext, useContext, useEffect } from "react";

type CurrentBranchIdContextValue = {
  currentBranchId: string | undefined | null;
  setCurrentBranchId: (branchId: string) => void;
  disabled: boolean;
  disableCurrentBranchSelect: () => void;
  enableCurrentBranchSelect: () => void;
};

const defaultValue: CurrentBranchIdContextValue = {
  currentBranchId: undefined,
  setCurrentBranchId: () => {},
  disabled: false,
  disableCurrentBranchSelect: () => {},
  enableCurrentBranchSelect: () => {},
};

const CurrentBranchIdContext = createContext<CurrentBranchIdContextValue>(defaultValue);

const useCurrentBranchId = (): CurrentBranchIdContextValue => {
  const { data: session } = useSession();

  const [disabled, { open: disableCurrentBranchSelect, close: enableCurrentBranchSelect }] = useDisclosure(false);
  const [currentBranchId, setCurrentBranchId] = useQueryState("branch", {
    scroll: false,
    shallow: false,
    clearOnDefault: false,
  });

  const previousBranchId = usePrevious(currentBranchId);

  useEffect(() => {
    if (!currentBranchId) {
      const branches = session?.user?.branches;
      setCurrentBranchId(previousBranchId || branches?.[0]?.id!);
    }
  }, [session, currentBranchId, previousBranchId]);

  return {
    currentBranchId: currentBranchId || previousBranchId,
    setCurrentBranchId,
    disabled,
    disableCurrentBranchSelect,
    enableCurrentBranchSelect,
  };
};

interface CurrentBranchIdContextProviderProps {
  children?: ReactNode;
}

export const CurrentBranchIdContextProvider: FC<CurrentBranchIdContextProviderProps> = ({ children }) => {
  const currentBranchIdState = useCurrentBranchId();

  return <CurrentBranchIdContext.Provider value={currentBranchIdState}>{children}</CurrentBranchIdContext.Provider>;
};

export const useCurrentBranchIdContext = () => useContext(CurrentBranchIdContext);

export const DisableCurrentBranchSelect: FC = () => {
  const { disableCurrentBranchSelect, enableCurrentBranchSelect } = useCurrentBranchIdContext();

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks
  useEffect(() => {
    disableCurrentBranchSelect();

    return () => enableCurrentBranchSelect();
  }, []);

  return null;
};
