import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/carousel/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/charts/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["DatesProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/dates/esm/components/DatesProvider/DatesProvider.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/dropzone/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["ModalsProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/modals/esm/ModalsProvider.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/@mantine/spotlight/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/home/runner/work/neotrack/neotrack/frontend/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["BranchesContextProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/src/branch/state/useBranchesContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentBranchIdContextProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/src/branch/state/useCurrentBranchIdContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaptureUserSession"] */ "/home/runner/work/neotrack/neotrack/frontend/src/lib/components/CaptureUserSession.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DefaultSWRConfig"] */ "/home/runner/work/neotrack/neotrack/frontend/src/lib/components/DefaultSWRConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogContextProvider"] */ "/home/runner/work/neotrack/neotrack/frontend/src/lib/components/PostHogContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RefreshOnFocus"] */ "/home/runner/work/neotrack/neotrack/frontend/src/lib/components/RefreshOnFocus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/home/runner/work/neotrack/neotrack/frontend/src/lib/theme.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/neotrack/neotrack/frontend/src/styles/globals.css");
