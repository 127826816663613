"use client";

import {
  ActionIcon,
  Alert,
  Badge,
  Breadcrumbs,
  Card,
  CardSection,
  Combobox,
  HoverCard,
  Indicator,
  MantineThemeOverride,
  Menu,
  Modal,
  MultiSelect,
  NavLink,
  Notification,
  NumberInput,
  Select,
  Stepper,
  Table,
  TableTd,
  TableTh,
  TableTr,
  Tabs,
  TabsList,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { Calendar, DatePickerInput } from "@mantine/dates";
import { dateFormat } from "~/utils/date";

import { IconCalendarMonth } from "@tabler/icons-react";
import classes from "../styles/theme.module.css";

export const theme: MantineThemeOverride = {
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: "subtle",
      },
    }),
    Alert: Alert.extend({
      styles: {
        title: {
          marginBottom: "var(--mantine-spacing-xs)",
        },
        message: {
          marginTop: 0,
        },
      },
    }),
    Badge: Badge.extend({
      styles: {
        label: { overflow: "initial" },
      },
    }),
    Breadcrumbs: Breadcrumbs.extend({
      styles: {
        root: { flexWrap: "nowrap" },
      },
    }),
    Calendar: Calendar.extend({
      defaultProps: {
        highlightToday: true,
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: "md",
        withBorder: true,
      },
    }),
    CardSection: CardSection.extend({
      defaultProps: {
        p: "sm",
        withBorder: true,
      },
    }),
    Combobox: Combobox.extend({
      defaultProps: {
        shadow: "md",
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        valueFormat: dateFormat,
        miw: 200,
        leftSection: <IconCalendarMonth />,
        clearable: true,
      },
    }),
    HoverCard: HoverCard.extend({
      defaultProps: {
        shadow: "md",
      },
    }),
    Indicator: Indicator.extend({
      defaultProps: {
        styles: {
          indicator: {
            zIndex: 100,
          },
        },
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        shadow: "md",
        position: "bottom-end",
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        closeOnClickOutside: false,
        transitionProps: { transition: "slide-up" },
      },
      classNames: {
        inner: classes.modalInner,
        content: classes.modalContent,
      },
      styles: {
        header: {
          alignItems: "start",
        },
        title: {
          fontSize: "var(--mantine-h3-font-size)",
          lineHeight: "var(--mantine-h3-line-height)",
          fontWeight: "var(--mantine-h3-font-weight)",
        },
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        miw: 200,
        checkIconPosition: "right",
      },
    }),
    NavLink: NavLink.extend({
      styles: {
        root: {
          borderRadius: "var(--mantine-radius-default)",
        },
      },
    }),
    Notification: Notification.extend({
      defaultProps: {
        withBorder: true,
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        inputMode: "decimal",
      },
    }),
    Select: Select.extend({
      defaultProps: {
        miw: 200,
        checkIconPosition: "right",
      },
    }),
    Stepper: Stepper.extend({
      defaultProps: {
        size: "xs",
      },
    }),
    Table: Table.extend({
      defaultProps: {
        verticalSpacing: "sm",
        horizontalSpacing: "xs",
        highlightOnHover: true,
        striped: true,
      },
    }),
    TableTd: TableTd.extend({
      defaultProps: {
        style: {
          whiteSpace: "pre-wrap",
          verticalAlign: "top",
        },
      },
    }),
    TableTh: TableTh.extend({
      defaultProps: {
        style: {
          whiteSpace: "nowrap",
        },
      },
    }),
    TableTr: TableTr.extend({
      defaultProps: {
        style: {
          display: "table-row",
        },
      },
    }),
    Tabs: Tabs.extend({
      defaultProps: {
        keepMounted: false,
      },
    }),
    TabsList: TabsList.extend({
      defaultProps: {
        style: {
          flexWrap: "nowrap",
        },
      },
    }),
    Text: Text.extend({
      defaultProps: {
        size: "sm",
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        resize: "block",
      },
    }),
    Title: Title.extend({
      defaultProps: {
        textWrap: "nowrap",
      },
    }),
  },
};
